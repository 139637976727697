import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import EducationPrograms from './EducationPrograms';  // Import the EducationPrograms component
import '../App.css';

const UniversityDetail = ({ universities }) => {
  const { id } = useParams(); // Assuming id is the university ID
  const university = universities.find((univ) => univ.id === parseInt(id));

  const [expandedMajors, setExpandedMajors] = useState([]); // State to track expanded majors

  if (!university) return <div className="error-message">University not found.</div>;

  // Function to toggle major expansion
  const toggleExpand = (majorName) => {
    setExpandedMajors((prevExpanded) =>
      prevExpanded.includes(majorName)
        ? prevExpanded.filter((name) => name !== majorName)
        : [...prevExpanded, majorName]
    );
  };

  // Function to determine color based on the ranking
  const getRankingColor = (rank) => {
    if (rank <= 250) return 'green'; // Best ranks
    if (rank <= 300) return 'orange'; // Mid-range ranks
    return 'red'; // Lower ranks
  };

  return (
    <div className="university-detail-page">
      {/* Left Sidebar with University Image and Apply button */}
      <div className="university-sidebar">
        <img src={university.imageUrl} alt={university.name} className="sidebar-image" />
        <h2 className="university-sidebar-name">{university.name}</h2>
        <p className="university-sidebar-info">
          {university.city}, {university.country}
        </p>
        <p>
          <strong>Dual Degree:</strong>{' '}
          {university.isDualDegree ? 'Yes' : 'No'}
        </p>
        <p>
          <strong>International:</strong>{' '}
          {university.isInternational ? 'Yes' : 'No'}
        </p>
        <p className="sidebar-fees">~ EGP {university.fees} / year</p>
      </div>

      {/* Main University Information */}
      <div className="university-main-info">
        {/* About the University */}
        <div className="about-university">
          <h3>About the University</h3>
          <p>{university.description}</p>
        </div>

        {/* Rankings Section */}
        <h3>Rankings</h3>
        <div className="rankings-box">
          <div className="ranking-item">
            <h3>QS Ranking</h3>
            <span className="ranking-number" style={{ color: getRankingColor(university.ranking.QS) }}>
              {university.ranking.QS}
            </span>
          </div>

          <div className="ranking-item">
            <h3>Times Higher Education</h3>
            <span className="ranking-number" style={{ color: getRankingColor(university.ranking.THE) }}>
              {university.ranking.THE}
            </span>
          </div>

          <div className="ranking-item">
            <h3>U.S. News</h3>
            <span className="ranking-number" style={{ color: getRankingColor(university.ranking.USNews) }}>
              {university.ranking.USNews}
            </span>
          </div>
        </div>

        <h3>General Requirements</h3>
        <p><strong>General SAT Requirement:</strong> {university.requirements.SAT}</p>
        <p><strong>General GPA Requirement:</strong> {university.requirements.GPA}</p>

        <h3>Student Testimony</h3>
        <p>"{university.testimony}"</p>

        {/* Educational Programs (Bachelor, Master, PhD) */}
        <div className="education-programs">
          <h3>Education Programs</h3>

          {/* List of Majors with Expandable Sections */}
          {university.majors.map((major, index) => (
            <div key={index} className="major-section">
              <div className="major-header">
                <h4>{major.name}</h4>
                <button onClick={() => toggleExpand(major.name)} className="expand-button">
                  {expandedMajors.includes(major.name) ? 'Collapse' : 'Expand'}
                </button>
              </div>

              {/* Expandable Major Details */}
              {expandedMajors.includes(major.name) && (
                <div>
                  <EducationPrograms majorDetails={major} />
                  
                
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UniversityDetail;
