import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { FaFacebook, FaInstagram, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import { FaXTwitter, FaWhatsapp } from 'react-icons/fa6';
import logo from '../assets/logo.png'; // Replace with your logo's path
import '../App.css'; // Assuming you're using the same CSS file

const Footer = () => {
  return (
    <footer className="custom-footer">
      <div className="footer-content">
        <img src={logo} alt="EDU Finders Logo" className="footer-logo" />
        <p>© 2024 EDU Finders</p>
      </div>
      <div className="social-icons">
        <a href="https://facebook.com/edufinderss" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
        <a href="https://x.com/EDU_Finders" target="_blank" rel="noopener noreferrer">
          <FaXTwitter />
        </a>
        <a href="https://www.instagram.com/edufinders/" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
        <a href="https://www.linkedin.com/company/edufinders/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
        {/* WhatsApp Button */}
        <a href="https://wa.me/201070219376" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp />
        </a>
        {/* Email Button */}
        <a href="mailto:hi@edufinders.com">
          <FaEnvelope />
        </a>
      </div>
      <div className="footer-links">
        {/* Link to About Page */}
        <Link to="/about" className="footer-about-link">About</Link>
      </div>
    </footer>
  );
};

export default Footer;
