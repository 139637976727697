import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import UniversityList from './components/UniversityList';
import UniversityDetail from './components/UniversityDetail';
import Header from './components/Header';
import Footer from './components/Footer';
import { fetchUniversities } from './api'; // Import the fetch function
import NotFound from './components/NotFound'; // 404 Page
import ScrollToTop from './components/ScrollToTop'; // Import your ScrollToTop component
import ProtectedRoute from './components/ProtectedRoute'; // Ensure the name matches your component
import Login from './components/login'; // Ensure the login page is properly named and imported
import { auth } from './firebase'; // Import Firebase Auth
import About from './components/About';

function App() {
  const [universities, setUniversities] = useState([]); // State to store universities
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null); // State to track error
  const [currentUser, setCurrentUser] = useState(null); // State to track the logged-in user

  // Firebase Auth Listener to track login status
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user); // Set the logged-in user
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, []);

  useEffect(() => {
    const loadUniversities = async () => {
      try {
        const universitiesData = await fetchUniversities();
        setUniversities(universitiesData);
      } catch (error) {
        console.error("Error fetching universities:", error);
        setError("Failed to load universities.");
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    loadUniversities();
  }, []);

  // Show loading or error message
  if (loading) {
    
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Router>
      <AppContent universities={universities} currentUser={currentUser} />
    </Router>
  );
}

function AppContent({ universities, currentUser }) {
  const location = useLocation(); // Hook to get current route
  const hideHeaderAndFooter = location.pathname === '/login'; // Check if the current path is login

  return (
    <>
      {/* Conditionally render Header */}
      {!hideHeaderAndFooter && <Header />}

      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <UniversityList universities={universities} />
            </ProtectedRoute>
          }
        />

        {/* Protected Route for University Details */}
        <Route
          path="/university/:id"
          element={
            <ProtectedRoute>
              <UniversityDetail universities={universities} />
            </ProtectedRoute>
          }
        />
      <Route path="/about" element={<ProtectedRoute>
        <About />
        </ProtectedRoute>} />
        
        {/* 404 Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>

      {/* Conditionally render Footer */}
      {!hideHeaderAndFooter && (
        <div className="footer-color">
          <Footer />
        </div>
      )}
    </>
  );
}

export default App;
