// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';  // Import Firebase Auth

const firebaseConfig = {
  apiKey: "AIzaSyC3DUsGbh1u9jlLHAvtpaNtuByGv0gmDxs",
  authDomain: "eduapp-98f9c.firebaseapp.com",
  projectId: "eduapp-98f9c",
  storageBucket: "eduapp-98f9c.appspot.com",
  messagingSenderId: "850587008968",
  appId: "1:850587008968:web:59bb93fc45d33841680afd",
  measurementId: "G-925HBFDVZS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);  // Initialize Firebase Auth

export { db, auth };
