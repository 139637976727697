import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import '../App.css';

// Import the image at the top
import landingImage from '../assets/landingimage.png';

const UniversityList = ({ universities }) => {
  const [search, setSearch] = useState(''); // For university name search
  const [filteredSuggestions, setFilteredSuggestions] = useState([]); // State to handle suggestions
  const [sortOption, setSortOption] = useState('name'); // Default sort option
  const [dualDegreeFilter, setDualDegreeFilter] = useState(false); // Dual degree filter state
  const [internationalFilter, setInternationalFilter] = useState(false); // International school filter state
  const [majorSearch, setMajorSearch] = useState(''); // Major search state
  const [activeTab, setActiveTab] = useState('university'); // For search tab switching
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 300)); // Simulating API load
      } catch (err) {
        setError('Failed to load universities.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);

    if (value) {
      const filtered = universities.filter((university) =>
        university.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleMajorSearchChange = (e) => {
    setMajorSearch(e.target.value);
  };

  const sortedUniversities = [...universities].sort((a, b) => {
    if (sortOption === 'name') {
      return a.name.localeCompare(b.name);
    } else if (sortOption === 'ranking') {
      const rankingA = a.ranking?.QS ?? Infinity;
      const rankingB = b.ranking?.QS ?? Infinity;
      return rankingA - rankingB;
    } else if (sortOption === 'fees-low-to-high') {
      const feesA = parseFloat(a.fees) || Infinity; // Parse fees to number
      const feesB = parseFloat(b.fees) || Infinity;
      return feesA - feesB; // Sort in ascending order (Low to High)
    }
    return 0;
  });

  const filteredUniversities = sortedUniversities.filter((university) => {
    // Apply search, dual degree, international, and major filters
    const matchesSearch = university.name.toLowerCase().includes(search.toLowerCase());
    const matchesDualDegree = dualDegreeFilter ? university.isDualDegree === true : true;
    const matchesInternational = internationalFilter ? university.isInternational === true : true;

    // Check if the major search term matches any major in the university
    const matchesMajor = majorSearch
      ? university.majors.some((major) =>
          major.name.toLowerCase().includes(majorSearch.toLowerCase())
        )
      : true;

    return matchesSearch && matchesDualDegree && matchesInternational && matchesMajor;
  });

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  if (loading) {
    return <div className="loading-screen">Loading universities...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div>
      {/* Landing Image */}
      <div className="landing-image-container">
        <img
          src={landingImage}
          alt="University Portal Landing"
          className="landing-image"
        />
        

        {/* Search Box with Search Options */}
        <div className="search-overlay">
          <div className="search-tabs">
            <button
              className={`search-tab ${activeTab === 'university' ? 'active' : ''}`}
              onClick={() => handleTabChange('university')}
            >
              University
            </button>
            <button
              className={`search-tab ${activeTab === 'majors' ? 'active' : ''}`}
              onClick={() => handleTabChange('majors')}
            >
              Majors
            </button>
          </div>

          <div className="search-container">
            {activeTab === 'university' && (
              <div className="search-box">
                <FaSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Search for a university..."
                  value={search}
                  onChange={handleInputChange}
                  className="search-input"
                />
              </div>
            )}
            {activeTab === 'majors' && (
              <div className="search-box">
                <FaSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Search by major..."
                  value={majorSearch}
                  onChange={handleMajorSearchChange}
                  className="search-input"
                />
              </div>
            )}

            {/* Sorting Dropdown */}
            <select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className="search-dropdown"
            >
              <option value="name">Sort by Name</option>
              <option value="ranking">Sort by QS Ranking</option>
              <option value="fees-low-to-high">Sort by Fees</option>
            </select>

            <button className="search-button">Search</button>
          </div>

          {/* Dual Degree and International Filters */}
          <div className="filters">
            <label>
              <input
                type="checkbox"
                checked={dualDegreeFilter}
                onChange={(e) => setDualDegreeFilter(e.target.checked)}
              />
              Dual Degree
            </label>
            <label>
              <input
                type="checkbox"
                checked={internationalFilter}
                onChange={(e) => setInternationalFilter(e.target.checked)}
              />
              International Universities
            </label>
          </div>
        </div>
      </div>

      {/* University List in Grid */}
      <div className="university-list">
        {filteredUniversities.length > 0 ? (
          filteredUniversities.map((university) => (
            <div className="university-card" key={university.id}>
              {/* University Image */}
              <div className="university-image-container">
                <img
                  src={university.imageUrl}
                  alt={university.name}
                  className="university-image"
                />
              </div>

              {/* University Details */}
              <div className="university-details">
                <h3>{university.name}</h3>
                <p><strong>QS Ranking:</strong> {university.ranking.QS}</p>
                <p>
                 <strong>Dual Degree:</strong>{' '}
                  {university.isDualDegree ? 'Yes' : 'No'} {/* Show 'Yes' or 'No' */}
                </p>
                <p>
                 <strong>International:</strong>{' '}
                  {university.isInternational ? 'Yes' : 'No'} {/* Show 'Yes' or 'No' */}
                </p>

                <p>{university.testimony}</p>
              </div>

              {/* Fees and Actions */}
              <div className="university-actions">
                <p className="fees">~ EGP {university.fees} / year</p>
                <Link to={`/university/${university.id}`} className="apply-button">
                  Learn More!
                </Link>
              </div>
            </div>
          ))
        ) : (
          <p>No universities match your search. Please try again.</p>
        )}
      </div>
    </div>
  );
};

export default UniversityList;
