// src/api.js
import { db } from './firebase'; // Import the Firestore instance
import { collection, getDocs } from 'firebase/firestore';

export const fetchUniversities = async () => {
  const universitiesCollection = collection(db, 'universities');
  const universitiesSnapshot = await getDocs(universitiesCollection);
  const universitiesList = universitiesSnapshot.docs.map(doc => ({
    id: doc.id, // Use the document ID
    ...doc.data(), // Spread the document data
  }));
  return universitiesList;
};
