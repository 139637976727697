import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Import Firebase Auth
import '../App.css'; // Assuming your styles are stored here
import logo from '../assets/logo.png'; // Replace with your logo path

const Header = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user); // Set the logged-in user
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut(); // Log the user out
      navigate('/login'); // Redirect to the login page after logout
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <header className="custom-header">
      <div className="header-content">
        {/* Logo */}
        <img src={logo} alt="Logo" className="header-logo" />
        
        <div className="header-title">
          <nav className="nav-bar">
            <Link to="/" className="nav-link">Home</Link>

          </nav>
        </div>
      </div>

      {/* Show email and Logout button if the user is logged in */}
      <div className="user-info">
        {user ? (
          <>
            <span className="greeting">Hello, {user.email}</span>
            <button onClick={handleLogout} className="logout-button">Logout</button>
          </>
        ) : (
          <Link to="/login" className="nav-link">Login</Link>
        )}
      </div>
    </header>
  );
};

export default Header;
