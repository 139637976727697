// src/components/About.js
import React from 'react';
import logo from '../assets/logo.png'; // Replace with your logo's path
import '../App.css'; // Assuming you're using the same CSS file for styling

const About = () => {
  return (
    <div className="about-page">
      {/* Logo at the top */}
      <img src={logo} alt="EDU Finders Logo" className="about-logo" />

      <h1>About EDU Finders</h1>
      <p>
        EDU Finders is a platform designed to help students find the best
        universities and programs to match their academic goals. We provide
        up-to-date information on university rankings, programs, fees, and more.
      </p>
      <p>
        Created by Students, Our mission is to make education accessible and to
        empower students in their journey to higher education. Whether you're
        looking for international universities or specialized programs, we've
        got you covered.
      </p>

      <h2>Our Vision</h2>
      <p>
        We believe that education is the key to unlocking potential, and we
        strive to connect students with the resources they need to succeed. Our
        platform brings together a vast array of academic opportunities from all
        over Egypt.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions, or would like to contact us for any reason, feel free to reach out to us at{' '}
        <a href="mailto:hi@edufinders.com">hi@edufinders.com</a>.
      </p>
    </div>
  );
};

export default About;
